<template>
    <div class="footerBar">
        <b-container>
            <b-row align-v="center" >
                <b-col lg="12" md="12" cols="12">
                    <p class="mb-0">
                        抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
                    </p>
                    <p class="mb-0">上海天游软件有限公司</p>
                    <p class="mb-0">网络文化经营许可证：沪网文[2018]9896-616号</p>
                    <p class="mb-0">增值电信业务经营许可证：沪B2-20050044</p>
                    <p class="mb-0">互联网出版许可证：新出网证（沪）字34号</p>
                    <p class="mb-0">文化部网络游戏举报和联系电子信箱：wlwh@vip.sina.com</p>
                    <p class="mb-0"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备10035374号</a></p>
                    <p class="mb-0"><a href="https://beian.mps.gov.cn/#/" target="_blank"><img src="ba_icon.png">沪公网安备:31010402334785号</a></p>
                    <p class="mb-0">公司地址: 上海市徐汇区桂平路391号A座19F</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style lang="scss" scoped>
    @media (max-width: 576px) {
        .footerBar {
            font-size: 14px;
        }

        .footerBar .p-partner-left {
            font-size: 18px !important;
            padding-top: 30px;
        }
    }

.footerBar {
    background-color: #2F333D;
    height: 250px;
        .align-items-center{
            padding-top: 20px;
            padding-bottom: 12px;
            background-color: #2F333D;
        }
        .p-partner-left {
            color: #FFFAF9;
            text-align: left;
            font-size: 22px;
        }
        .white-left {
            margin-top: 20px;
            float: left;
            color: #FFFAF9;
            font-size: 12px;
            text-align: center;
        }

        .white-foot {
            background: #fff;
            width: 128px;
            height: 128px
        }

        .p-right {
            margin-top: 20px;
            color: #FFFAF9;
            font-size: 14px;
            float: left;
            text-align: left;
            margin-left: 20px;
        }
    }
</style>
