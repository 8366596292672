<template>
  <footer class="lcg-footer">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-column align-items-start">
            <div class="footerlogo-box d-flex align-items-center">
              <img
                src="@/assets/images/home/head_logo_dark.png"
                class="img-auto"
              />
            </div>
            <p class="mb-0">
              Copyright © 2020
              <a href="#" class="">上海天游软件有限公司</a>. All rights
              Reserved
            </p>
            <p class="mb-0">
              适龄提示: 本公司产品适合 10 周岁以上玩家使用
              <a href="#" class="ml-2">未成年人家长监护</a>
              <router-link to="/privacypolicy" class="ml-2"
                >隐私政策</router-link
              >
              <a href="#" class="color83 ml-2">侵权投诉</a>
            </p>
            <p class="mb-0"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备10035374号</a></p>
            <p class="mb-0"><a href="https://beian.mps.gov.cn/#/" target="_blank"><img src="<%= BASE_URL %>ba_icon.png">沪公网安备:31010402334785号</a></p>
            <p class="mb-0">上海天游软件有限公司</p>
            <p class="mb-0">公司地址: 上海市徐汇区桂平路391号A座19F</p>
            <p class="mb-0">
              <a href="#" class="mr-2">互联网文化经营单位</a>
              违法信息举报电话：021-54270100
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
